import { useRef } from "react";
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from "react-share";

import DonateBtn from "../DonateBtn/DonateBtn";
import footerOne from "../../assets/images/footer-1.webp";
import footerTwo from "../../assets/images/footer-2.webp";
import footerThree from "../../assets/images/footer-3.webp";
import footerFour from "../../assets/images/footer-4.webp";
import sflWhiteLogo from "../../assets/images/SFL-LOGO-WHITE.svg";

import "./footer.css";

const Footer = () => {
  const shareUrl = "https://studentsforliberty.org/";

  const linkRef = useRef();

  function copyCurrentUrl() {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    linkRef.current.classList.add("clicked");
    setTimeout(() => {
      linkRef.current.classList.remove("clicked");
    }, 2000);
  }
  return (
    <>
      <footer className="footer bg-dark-grey">
        <div className="container">
          <div className="footer-first-wrapper">
            <h3>
              Invest in
              <br /> Liberty
            </h3>
            <DonateBtn />
            <a href="#" className="sfl-website">
              <img src={sflWhiteLogo} alt="sfl-logo" />
            </a>
          </div>
          <div className="footer-second-wrapper">
            <div className="footer-images-wrapper">
              <img src={footerOne} alt="reward" />
              <img src={footerTwo} alt="reward" />
              <img src={footerThree} alt="reward" />
              <img src={footerFour} alt="reward" />
            </div>
            <div className="share-report">
              <p>Share Report:</p>
              <div className="footer-social-icons-wrapper">
                <FacebookShareButton url={shareUrl}>
                  <span className="icon-facebook"></span>
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl}>
                  <span className="icon-twitter"></span>
                </TwitterShareButton>
                <EmailShareButton url={shareUrl}>
                  <span className="icon-email"></span>
                </EmailShareButton>
                <a
                  ref={linkRef}
                  onClick={copyCurrentUrl}
                  data-message="Page link copied to clipboard!"
                  className="copy-to-clipboard"
                >
                  <span className="icon-link"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-end">
        <p>© 2024 Students For Liberty, All Rights Reserved</p>
        <a href="">Privacy Policy</a>
        <p>|</p>
        <a href="">Disclaimer</a>
        <p>|</p>
        <a href="">Terms & Conditions</a>
      </div>
    </>
  );
};

export default Footer;
