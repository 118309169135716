import { gsap } from "gsap";

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
const animateTitle1 = (title) => {
  const chars = title.querySelectorAll(".char");

  gsap.fromTo(
    chars,
    {
      "will-change": "opacity, transform",
      opacity: 0,
      scale: 0.6,
      rotationZ: () => gsap.utils.random(-20, 20),
    },
    {
      ease: "power4",
      opacity: 1,
      scale: 1,
      rotation: 0,
      stagger: 0.4,
      scrollTrigger: {
        trigger: title,
        start: "center+=20% bottom",
        end: "+=50%",
        scrub: true,
      },
    }
  );
};
const animateTitle3 = (title) => {
  const chars = title.querySelectorAll(".char");

  gsap.fromTo(
    chars,
    {
      "will-change": "opacity, transform",
      opacity: 0,
      xPercent: () => gsap.utils.random(-200, 200),
      yPercent: () => gsap.utils.random(-150, 150),
    },
    {
      ease: "power1.inOut",
      opacity: 1,
      xPercent: 0,
      yPercent: 0,
      stagger: { each: 0.05, grid: "auto", from: "random" },
      scrollTrigger: {
        trigger: title,
        start: "center bottom+=10%",
        end: "bottom center",
        scrub: 0.9,
      },
    }
  );
};

export { animateTitle1, animateTitle3 };
