import "./donate-btn.css";

import Btn from "../Btn/Btn";

const DonateBtn = () => {
  return (
    <Btn href="#" className="donate-btn" text="Donate Now" />
  )
};

export default DonateBtn;
