import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import LetterFromCEO from "../components/LetterFromCEO/LetterFromCEO";
import YearInNumbers from "../components/YearInNumbers/YearInNumbers";
import WhySFLExist from "../components/WhySFLExist/WhySFLExist";
import FeaturedStories from "../components/FeaturedStories/FeaturedStories";
import GlobalPresence from "../components/GlobalPresence/GlobalPresence";
import MentionsQuotesCommentary from "../components/MentionsQuotesCommentary/MentionsQuotesCommentary";
import BoardOfDirectors from "../components/BoardOfDirectors/BoardOfDirectors";
import InvestInLiberty from "../components/InvestInLiberty/InvestInLiberty";
import Finances from "../components/Finances/Finances";
import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <LetterFromCEO />
      <YearInNumbers />
      <WhySFLExist />
      <FeaturedStories />
      <GlobalPresence />
      <MentionsQuotesCommentary />
      <BoardOfDirectors />
      <InvestInLiberty />
      <Finances />
      <Footer />
    </>
  );
};

export default Home;
