import "./line.css";
const Line = () => {
  return (
    <svg
      width="275"
      height="230"
      viewBox="0 0 275 230"
      xmlns="http://www.w3.org/2000/svg"
      id="arrow"
    >
      <defs>
        <mask id="reveal">
          <rect
            x="0"
            y="0"
            width="0"
            height="230"
            fill="white"
            id="mask-rect"
          />
        </mask>
      </defs>
      <g mask="url(#reveal)">
        <path
          d="M107.284 90.2945C104.9 92.4457 102.906 94.4851 100.249 96.5046C99.6234 97.1032 98.2982 97.0634 97.1277 97.3986C83.4792 99.2109 70.2579 101.53 57.501 104.975C42.4033 109.09 28.0425 114.462 14.8829 122.217C11.6812 123.973 8.47956 125.729 5.16024 127.728C5.04257 127.972 4.65243 128.084 4.26229 128.195C3.36435 128.662 2.03912 128.622 1.45707 127.74C1.0298 127.234 0.683077 125.865 1.03609 125.134C1.74211 123.674 2.99308 122.476 4.5165 121.411C14.6355 113.688 26.0364 107.486 38.6013 103.047C53.7425 97.4517 69.8124 94.1068 86.3467 91.8874C92.3907 91.2048 98.4347 90.5222 104.206 89.7079C104.987 89.4844 105.922 89.636 106.974 89.5442C106.974 89.5442 107.247 89.6759 107.402 90.051M99.6297 95.004C100.336 93.5433 99.636 92.9048 98.3107 92.8649C97.7658 92.6015 96.8679 93.0685 96.0876 93.292C95.0348 93.3838 94.1369 93.8507 93.3195 93.4557C91.6847 92.6655 90.3966 93.2442 89.1085 93.8229C87.9752 94.7768 86.65 94.7369 85.2877 94.0785C83.6529 93.2883 82.21 93.4919 81.2315 94.8209C80.9962 95.3078 80.7608 95.7947 80.3707 95.9065C76.3208 94.5496 74.4878 94.865 72.9209 97.4112C73.9737 97.3194 74.754 97.0959 75.4166 97.1159C76.587 96.7806 77.7574 96.4453 78.7296 97.2155C80.3644 98.0057 81.5348 97.6704 82.1231 96.4532C82.8292 94.9925 84.1544 95.0323 85.5167 95.6908C86.8791 96.3493 88.0866 96.6326 89.2199 95.6787C90.6257 94.8566 91.9137 94.2779 93.821 95.1997C94.3659 95.4631 95.4187 95.3713 95.9265 95.0161C97.3323 94.1939 98.2673 94.3455 99.6297 95.004ZM70.6235 96.6011C70.6235 96.6011 67.8183 96.1462 67.038 96.3698C66.2577 96.5933 65.7871 97.5671 65.434 98.2974C65.3164 98.5409 66.0532 99.798 66.4434 99.6863C67.3785 99.8379 68.4312 99.7461 69.0567 99.1474C69.5645 98.7922 69.7627 97.6867 70.351 96.4694"
          fill="black"
        />
        <path
          d="M252.654 98.991C249.93 97.6741 247.323 96.1137 245.106 94.4415C244.016 93.9148 243.316 93.2762 242.462 92.2625C241.607 91.2489 241.415 90.2551 242.159 89.413C242.629 88.4392 244.072 88.2356 245.162 88.7624C246.252 89.2892 247.342 89.8159 248.432 90.3427C256.054 96.1294 264.383 100.455 272.867 105.157C272.867 105.157 272.749 105.4 272.631 105.643C266.544 107.807 260.184 109.838 254.097 112.002C252.926 112.337 252.028 112.804 250.703 112.764C250.041 112.744 248.951 112.217 248.523 111.71C247.979 111.447 248.294 110.098 248.53 109.611C249.273 108.769 250.406 107.815 251.577 107.48C255.206 106.231 258.872 105.6 262.383 104.594C262.773 104.482 263.436 104.502 264.606 104.167C263.516 103.64 262.853 103.62 262.191 103.6C245.867 100.516 229.389 97.056 212.75 95.3204C190.264 93.1619 167.501 92.9709 145.046 93.5302C143.993 93.622 142.941 93.7139 141.343 93.5423C143.065 91.3712 144.161 89.7987 145.647 88.1144C145.882 87.6275 146.935 87.5357 147.87 87.6874C181.316 87.3353 214.249 89.4377 247.317 98.2129C248.797 98.628 250.277 99.043 251.874 99.2146C251.874 99.2146 251.874 99.2146 252.654 98.991ZM147.938 91.0238C147.938 91.0238 150.199 91.2153 151.016 91.6104C152.651 92.4006 153.976 92.4404 155.109 91.4866C156.478 90.0458 158.076 90.2174 159.983 91.1392C161.073 91.666 162.008 91.8176 163.023 91.1072C164.664 89.7981 164.937 89.9298 166.999 91.2269C168.361 91.8853 169.179 92.2804 170.739 91.8334C171.52 91.6099 172.455 91.7615 173.507 91.6697C172.071 89.774 171.798 89.6423 171.291 89.9975C169.767 91.0632 168.405 90.4047 166.77 89.6145C164.863 88.6926 163.383 88.2776 161.896 89.9618C161.779 90.2053 161.389 90.3171 160.608 90.5406C157.537 87.8547 154.725 89.4991 152.849 91.2951C149.425 89.3396 148.762 89.3196 148.093 91.399M179.081 91.9609C179.081 91.9609 178.307 90.0851 177.762 89.8218C176.944 89.4267 175.892 89.5185 175.693 90.624C175.458 91.1109 175.922 92.2363 176.195 92.368C176.74 92.6314 177.52 92.4079 179.236 92.336"
          fill="black"
        />
        <path
          d="M138.903 88.1585L138.668 88.6454C134.754 93.9614 134.754 93.9614 127.775 94.4924C122.629 94.7081 117.638 95.2989 112.374 95.758C111.321 95.8498 110.386 95.6981 108.943 95.9017C111.055 93.6188 112.777 91.4477 114.653 89.6517C114.888 89.1648 115.941 89.073 116.604 89.0929C123.855 88.6936 131.107 88.2944 138.241 88.1385C138.241 88.1385 138.786 88.4019 139.058 88.5336"
          fill="black"
        />
        <path
          d="M103.135 96.0974C105.519 93.9462 107.903 91.795 110.287 89.6438C110.405 89.4004 111.34 89.552 112.783 89.3484C110.554 91.8748 108.95 93.8025 106.955 95.8419C106.212 96.684 104.615 96.5124 103.444 96.8477C103.444 96.8477 103.172 96.716 103.017 96.3409"
          fill="black"
        />
        <path
          d="M136.977 93.5343C138.191 91.7184 139.56 90.2776 140.928 88.8369C141.164 88.35 142.489 88.3898 143.542 88.298C141.702 90.7126 140.953 93.654 137.677 94.1729C137.677 94.1729 137.404 94.0412 137.132 93.9095"
          fill="black"
        />
      </g>
    </svg>
  );
};
export default Line;
