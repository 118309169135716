import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";

import educate from "../../assets/icons/educate.png";
import develop from "../../assets/icons/develop.png";
import empower from "../../assets/icons/empower.png";
import sflexists from "../../assets/images/why-slf-exists.webp";
import "./why-sfl-exist.css";

const WhySFLExist = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();
    animateTitle1(titleRef.current);
  }, []);
  return (
    <section className="why-sfl-exist bg-yellow border-radius-35">
      <div className="container">
        <h2>Why does SFL exist</h2>
        <h3 ref={titleRef} data-splitting>
          Combating groupthink by developing,
          <br /> education and empowering future leaders of liberty
        </h3>
        <div className="why-sfl-exist__wrapper">
          <div className="why-sfl-exist__problem">
            <h2>problem</h2>
            <div className="border-radius-20">
              <h4>Colleges and Universities</h4>
              <p>
                Colleges and universities neither teach the ideas of liberty nor
                do they provide students with the skills they need to succeed in
                the workforce. They are supposed to be centers of knowledge
                creation, but they have become indoctrination camps brimming
                with groupthink.
              </p>
            </div>
          </div>
          <div className="why-sfl-exist__solution">
            <h2>solution</h2>
            <div className="solution-cards-wrapper">
              <div className="solution-card border-radius-20">
                <div>
                  <span>1</span>
                  <img src={educate} alt="Educate" />
                </div>
                <h4>Educate</h4>
                <p>
                  Over 1,315,314 people have attended SFL events to learn about
                  free markets, free speech, and the ideas of a free society.
                </p>
              </div>
              <div className="solution-card border-radius-20">
                <div>
                  <span>2</span>
                  <img src={develop} alt="Develope" />
                </div>
                <h4>Develop</h4>
                <p>
                  SFL hosts online and in-person training to develop student
                  leadership, communication, and organizational skills to help
                  them become more effective advocates for the ideas of liberty.
                </p>
              </div>
              <div className="solution-card border-radius-20">
                <div>
                  <span>3</span>
                  <img src={empower} alt="Empower" />
                </div>
                <h4>Empower</h4>
                <p>
                  SFL volunteers are in charge of our events and many aspects of
                  our programs. Training plus experience is our winning formula
                  for producing leaders of liberty.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={sflexists} alt="Why SFL Exists" />
    </section>
  );
};

export default WhySFLExist;
