import { useEffect, useRef, useState } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";
import Masonry from "react-responsive-masonry";
import { ResponsiveMasonry } from "react-responsive-masonry";
import LogoSlider from "../LogoSlider/LogoSlider";
import "./mentions.css";

const Mentions = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();

    animateTitle1(titleRef.current);
  }, []);

  const mediaHits = [
    {
      title:
        "Five ways Ayn Rand can save America: Empowered individuals, not government, are the key to national salvation",
      descr: "MSN.com, Fox News Channel, AOL News, Yahoo! Lifestyle",
      views: "313M",
    },
    {
      title:
        "Ted Cruz faces bipartisan fire for criticising Uganda’s new anti-LGBT+ law",
      descr: "Yahoo! News, The Independent, eClips Yahoo! News UK",
      views: "156M",
    },
    {
      title: "Justin Amash: 'I'd Impeach Every President'",
      descr: "Yahoo! News, Reason",
      views: "95M",
    },
    {
      title:
        "David Boaz: Libertarianism Is the Intellectual Core of Liberalism",
      descr: "Reason, AOL News, Yahoo! News",
      views: "313M",
    },
    {
      title:
        "Fifteen demonstrators demand an 'end to the oppression of women in Iran'",
      descr: "Infobae",
      views: "67M",
    },
    {
      title:
        "South Dakota Gov. Kristi Noem endorses Texas dental practice in ‘extremely bizarre’ social media infomercial",
      descr: "New York Post",
      views: "53M",
    },
    {
      title:
        "Five ways Ayn Rand can save America: Empowered individuals, not government, are the key to national salvation",
      descr: "MSN.com, Fox News Channel, AOL News, Yahoo! Lifestyle",
      views: "313M",
    },
    {
      title:
        "Ted Cruz faces bipartisan fire for criticising Uganda’s new anti-LGBT+ law",
      descr: "Yahoo! News, The Independent, eClips Yahoo! News UK",
      views: "156M",
    },
    {
      title: "Justin Amash: 'I'd Impeach Every President'",
      descr: "Yahoo! News, Reason",
      views: "95M",
    },
    {
      title:
        "David Boaz: Libertarianism Is the Intellectual Core of Liberalism",
      descr: "Reason, AOL News, Yahoo! News",
      views: "313M",
    },
    {
      title:
        "Fifteen demonstrators demand an 'end to the oppression of women in Iran'",
      descr: "Infobae",
      views: "67M",
    },
    {
      title:
        "South Dakota Gov. Kristi Noem endorses Texas dental practice in ‘extremely bizarre’ social media infomercial",
      descr: "New York Post",
      views: "53M",
    },
  ];

  const colors = [
    "#eec599", // Peach
    "#d9c1d9", // Pink
    "#88c3ca", // Aqua
    "#f1f1f1", // Grey
  ];

  const handleReadMoreClick = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="mentions bg-white">
      <div className="container">
        <div className="mentions-content-wrapper">
          <div className="mentions-wrapper">
            <h2>mentions, quotes, and commentary</h2>
            <h3 ref={titleRef} data-splitting>
              Students For Liberty
              <br /> in the Media
            </h3>
            <h2>media mentions, quotes, and commentary</h2>
          </div>
          <div className="media-hits-wrapper">
            <div className="border-radius-20">
              <p>647</p>
              <p>individual media hits</p>
              <span className="icon-Icons_Individual-Media-Hits icomoon"></span>
            </div>
          </div>
        </div>

        <div className={`mentions-media-hits ${isExpanded ? "expanded" : ""}`}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry gutter="20px">
              {mediaHits.map((hit, i) => {
                const color = colors[i % colors.length];

                return (
                  <div
                    key={i}
                    className="media-hit border-radius-20 "
                    style={{ backgroundColor: color }}
                  >
                    <h4>{hit.title}</h4>
                    <p>{hit.descr}</p>
                    <p className="media-views">
                      <span className="icon-eye"></span>
                      {hit.views}
                    </p>
                  </div>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </div>

        <a href="/#" onClick={handleReadMoreClick}>
          {isExpanded ? "See less ‹" : "See more media hits ›"}
        </a>
        <div className="mentions-logos">
          <h2>SFL was mentioned in</h2>
          <div className="mentions-logos-wrapper bg-grey border-radius-20">
            <LogoSlider />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mentions;
