import "./btn.css";

const Btn = ({ text, href = "#", className = "" }) => {
  return (
    <a href={href} className={`${className} btn`}>
      {text}
    </a>
  );
};

export default Btn;
