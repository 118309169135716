

const revenue = [
    {
        id: 1,
        label: "Contributions and grants",
        percentage: "89.77%",
        color: "#eec499",

    },
    {
        id: 2,
        label: "Events sponsorship/registration",
        percentage: "5.77%",
        color: "#d8c1d9",

    },
    {
        id: 3,
        label: "Investment/other income",
        percentage: "2.84%",
        color: "#85c1c7",

    },
    {
        id: 4,
        label: "Inkind",
        percentage: "1.62%",
        color: "#fac813",
    }
];

const expenses = [
    {
        id: 1,
        label: "Programs",
        percentage: "82.22%",
        color: "#eec499",
    },
    {
        id: 2,
        label: "Administrative",
        percentage: "9.11%",
        color: "#d8c1d9",
    },
    {
        id: 3,
        label: "Development",
        percentage: "8.67%",
        color: "#85c1c7",
    },
];


export { expenses, revenue };