import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";

import VideoSlider from "../VideoSlider/VideoSlider";

import lImage from "../../assets/images/learn-liberty.webp";
import lLogo from "../../assets/images/ll-logo.webp";

import "./learn.css";

const LearnLiberty = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();

    animateTitle1(titleRef.current);
  }, []);

  return (
    <section className="commentary bg-grey">
      <div className="container">
        <div className="commentary-top-content">
          <div className="commentary-headings-wrapper">
            <h2>watch, learn, live</h2>
            <h3 ref={titleRef} data-splitting>
              Learn Liberty yields great results
            </h3>
          </div>
          <div className="commentary-img-wrapper">
            <img src={lLogo} alt="Learn Liberty" />
          </div>
        </div>
        <div className="commentary-content-wrapper">
          <div className="commentary-content-left">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <div className="views-wrapper">
              <div className="commentary-views-img-wrapper">
                <span className="icon-Icons_Learn-Liberty icomoon"></span>
              </div>
              <div>
                <p>6.49M</p>
                <p>Views in FY 24</p>
              </div>
            </div>
          </div>
          <img src={lImage} className="learn-libert-img" alt="Learn Liberty" />
        </div>
        <h2>top 5 best performing video</h2>
      </div>

      <VideoSlider />
    </section>
  );
};

export default LearnLiberty;
