import Mentions from "../Mentions/Mentions";
import LearnLiberty from "../LearnLiberty/LearnLiberty";
import Testimonials from "../Testimonials/Testimonials";
import "./mentions-quotes-commentary.css";

const MentionsQuotesCommentary = () => {
  return (
    <>
      <Mentions />
      <LearnLiberty />
      <Testimonials />
    </>
  );
};

export default MentionsQuotesCommentary;
