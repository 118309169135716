import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";

import directors from "../../resources/boardOfDirectors";

import "./board-of-directors.css";

const BoardOfDirectors = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();

    animateTitle1(titleRef.current);
  }, []);

  return (
    <section className="board-of-directors bg-grey">
      <div className="container">
        <h2>Students For Liberty</h2>
        <h3 ref={titleRef} data-splitting>
          Board Of Directors
        </h3>
        <div className="directors-grid-wrapper">
          {directors.map((director, i) => (
            <div key={i} className="director-card">
              <img
                src={director.thumbnail}
                alt={`${director.name}'s thumbnail`}
              />
              <p>
                <b>{director.name}</b>
              </p>
              <p>{director.position}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BoardOfDirectors;
