//North America Images
import image1 from "../assets/images/around-the-globe/North-America/1.webp";
import image2 from "../assets/images/around-the-globe/North-America/2.webp";
import image3 from "../assets/images/around-the-globe/North-America/3.webp";
//Asia Pacific Images
import image4 from "../assets/images/around-the-globe/Asia-Pacific/1.webp";
import image5 from "../assets/images/around-the-globe/Asia-Pacific/2.webp";
//Europe Images
import image6 from "../assets/images/around-the-globe/Europe/1.webp";
import image7 from "../assets/images/around-the-globe/Europe/2.webp";
import image8 from "../assets/images/around-the-globe/Europe/3.webp";
//Africa Images
import image9 from "../assets/images/around-the-globe/Africa/1.webp";
import image10 from "../assets/images/around-the-globe/Africa/2.webp";
import image11 from "../assets/images/around-the-globe/Africa/3.webp";
//Eslibertad Images
import image12 from "../assets/images/around-the-globe/EsLibertad/1.webp";
//MENA Images
import image13 from "../assets/images/around-the-globe/MENA/1.webp";
import image14 from "../assets/images/around-the-globe/MENA/2.webp";
//South-Asia Images
import image15 from "../assets/images/around-the-globe/South-Asia/1.webp";
import image16 from "../assets/images/around-the-globe/South-Asia/2.webp";
import image17 from "../assets/images/around-the-globe/South-Asia/3.webp";
//Brazil Images
import image18 from "../assets/images/around-the-globe/Brazil/1.webp";
import image19 from "../assets/images/around-the-globe/Brazil/2.webp";

const stories = {
  "North America": [
    {
      location: "Tennessee",
      name: "Pepper Spray Persuasion",
      descr: [
        "The University of Tennessee has long reported one of the highest rates of sexual assault in the nation, and yet the university’s ban on pepper spray left students perpetually vulnerable. That is until those rates became real for the Students For Liberty chapter at UT.",
        "When its members heard what had happened to several of their friends on and around campus, they were shocked, revolted, and angry. But more than anything, they were moved to act. Their demand was simple: that the university recognize individuals have a right to defend themselves. They collected signatures on a petition, but in their first meeting with campus administration, they were told in no uncertain terms: This policy would not change.",
        "Keaton Powell, one of the movement’s leaders, and now an SFL staff member said, “Instead of being demoralized, let’s be even more determined. It lit a major fire under us.” After two years of fighting, they won their fight. Tennessee’s governor signed HB 1909: The Laken Riley Act of 2024. It prevents universities from prohibiting “adults lawfully present on the institution's property from carrying a non-lethal weapon for purposes of self-defense.”",
        "Students now can legally carry effective self-defense tools, though that represents just one small step; for Tennessee, there are more unjust policies to change. But for SFL and the liberty movement more generally, it demonstrates the power of student activism: When students stand together, united by a just cause, they can and do create real change on their campuses, and that change can and does spread into the broader world.",
      ],
      image: image1,
      id: "Tennessee",
    },
    {
      location: "New Hampshire",
      name: "Free Speech from the Heart ... and the Dartmouth",
      descr: [
        "One Students For Liberty chapter raised more than $18,000 in one cloudless, spring day, with one perfectly executed summit. It started simply enough: with coffee and pastries from Lou’s Bakery — a Hanover, NH institution. But by the time it ended, the following people had spoken: Dr. Peter Boghossian, Spike Cohen, Billy Binion (Associate Editor at Reason), Margaux Granath (Program Officer at the Foundation for Individual Rights and Expression), Nadine Strossen, (former President of the ACLU), and Jason Sorens (Senior Fellow at AIER).",
        "At Dartmouth University on April 13th, 2024, those guests talked about the complexities of free speech; for example, whether hate speech and false speech should be treated differently from ordinary speech. They talked about the importance of true understanding instead of mere debate; about conservative and libertarian journalism; about government and online censorship; and about the evolving role of academic institutions. Cohen’s discussion of “free speech absolutism” was especially engaging. He defended the principle of unrestricted free speech, allowing only minimal exceptions for speech that incites violence or disseminates harmful falsehoods.",
        "The Free Speech Summit not only united members of the Dartmouth student community, it raised more money than any regional conference in SFL North American history, and above all, showed — just as the chapter at Tennessee did — how much is possible when a few students get together and try to make something happen.",
      ],
      image: image2,
      id: "New Hampshire",
    },
    {
      location: "Washington, DC",
      name: "LibertyCon International Hits New Heights",
      descr: [
        "It was Groundhog Day … but you knew when you arrived in Washington, D.C., on February 2, 2024, that you were in for a once — and only once — in-a-lifetime experience. That’s when LibertyCon launched beneath the Grand Hyatt’s tall, windowed, bright lobby.",
        "On that first night of the conference, Reason Magazine Senior Editor Robby Soave welcomed 817 attendees. Later that night, one of the lions of the liberty movement spoke about the rise of illiberalism, and how it lurks in the shadows of liberal triumph. Knowing these would be his last public remarks, terminally ill with esophageal cancer, David Boaz ceded the stage for one last time, by inspiring the next generation of leaders for liberty. “Now,” he said with a catch in his voice, “it’s your turn to pick up the banner … Extend liberty to more parts of the world and more parts of life, and make the 21st Century the most liberal century yet. Thank you very much, and good luck.”",
        "The attendees came from 51 different countries, but by the time John Stossel delivered his keynote speech on the Saturday night of LibertyCon, they had learned together — about Central Bank Digital Currencies, from Robert Breedlove; about the challenges of journalism, from Katherine Mangu-Ward; about the philosophy of Human Respect from Spike Cohen. But more than that, those 817 people from 51 different countries had laughed together, bonded together, and reaffirmed their passion for the cause that had brought them together.",
      ],
      image: image3,
      id: "Washington, DC",
    },
  ],
  "Asia Pacific": [
    {
      location: "Asia Pacific",
      name: "Let Liberty Raina",
      descr: [
        "Raina Salsabil has taken the Asia-Pacific region to new heights. In her role as local coordinator, she created partnerships with organizations which enabled the first-ever regional conference in Indonesia to come to fruition. ",
        "Raina also coordinated eight educational events that covered topics like freedom of speech, Objectivism, and free-market environmentalism. She arranged 15 recruitment events, resulting in 361 leads from 21 countries worldwide, yielding 86 applications to the Local Coordinator Program. With Raina’s effort, SFL now has networks of volunteers in countries it hadn’t previously reached — namely, Thailand, Taiwan, Timor-Leste, and Cambodia.",
        "But Raina’s influence went beyond Asia; she represented Students For Liberty at the International Academy for Leaders (IAF) Seminar by the Friedrich Naumann Foundation in Germany, the Prometheus Summit in Kenya, and LibertyCon International in Washington, D.C. She also participated as a panelist in the book launch of the translated version of After the Welfare State, which garnered media attention from 12 different outlets. And on that front, Raina expanded SFL Asia-Pacific's social media presence through her activity on Instagram. Her videos and posts there reached an average of 3,500 viewers.",
      ],
      image: image4,
      id: "Asia Pacific",
    },
    {
      location: "Asia Pacific 2",
      name: "Nine New Countries (and All the Established Ones) Freshly Infused with SFL Energy",
      descr: [
        "In 2024, Students For Liberty expanded to nine new counties in the Asia-Pacific region, including places in which no libertarian organization has ever been represented, including Cambodia, Brunei, Myanmar, Timor-Leste, and Papua New Guinea. SFL also returned to Taiwan. Said one local coordinator, “About a year and a half ago, we were still talking about Asia-Pacific as a new region, but now it's quite a bit more than that. We have a true, legitimate libertarian movement here!”",
        "When SFL’s presence in the Asia-Pacific region began in FY 2019, it registered six events and had 20 coordinators. Since then, and despite the challenges posed by the pandemic, Asia-Pacific SFL had, as of publication of this report, organized 264 events, collected 6,678 contacts, reached 10,982 students, and organized 44 trainings, including two in-person retreats. One of those retreats was the region’s largest-ever Top Leadership Retreat; 30 coordinators were in attendance.",
        "But the ideas that form the foundation of Western, classically liberal societies are still not widespread in this part of the world, and each country not only has its own language, but many different dialects. These challenges have been potent, but in the past five years, Students For Liberty has grown its presence from three countries to 18, which makes the Asia-Pacific region the third-largest within the organization.",
        "Moreover, compared to this time last year, the Asia-Pacific region has organized twice as many events. Just as importantly, the quality of the events has dramatically improved. SFL Indonesia recently hosted book talks on Anatomy of the State, The Law, and Tom Palmer’s Morality of Liberty series. More than 70 students in Indonesia also attended a timely discussion on how new laws in the country, intended to combat cybercrime, are being used to criminalize cyberactivists. Still another event, The Role of the Private Sector in Advancing Liberty and Learning, brought in 130 students who discussed how private initiatives can advance liberty and individual empowerment through education.",
      ],
      image: image5,
      id: "Asia Pacific 2",
    },
  ],
  Europe: [
    {
      location: "Europe",
      name: "Amidst Blackouts, the Torch of Liberty Shines in Ukraine",
      descr: [
        "With a hostile army invading, and a full-scale war ongoing, it would have been easy for SFL Europe to close up shop in Ukraine — at least until the end of the war. But the enemies of liberty don’t seem to rest, so neither do SFLers.",
        "The first Ukrainian Renaissance took place in Ivano-Frankivsk, Ukraine, in July of 2023; it was the first SFL event in the country since the start of the invasion; indeed, the first liberty event in Ukraine since 2018. But parts of the university that hosted that event were destroyed by a Russian missile. That sent organizers scrambling, but they orchestrated a minor miracle: In Ludwig von Mises’s hometown of Lviv, on July 20 and 21, 2024, the second annual Ukrainian Renaissance conference featured nearly 40 speakers from throughout Europe, who spoke to packed auditoriums. In sum, 230 people from 17 different countries attended.",
        "That’s not to say the event went off without a hitch. Because of targeted Russian attacks on the Ukrainian energy infrastructure, the conference faced repeated blackouts, and several sessions took place without loudspeakers, in dark rooms. But the show — and the discussion of liberty and the futures of Ukraine, Europe, and liberalism around the world — went on, including a charity auction, and a tour of Lviv, which naturally included a stop at the former home of Ludwig von Mises.",
      ],
      image: image6,
      id: "Europe",
    },
    {
      location: "Europe 2",
      name: "LibertyCon Europe Takes Flight in Tbilisi",
      descr: [
        "LibertyCon Europe began beneath a wooden roof supported by wooden beams that called to mind (though were much sturdier than) those in Tbilisi’s old town. That’s where SFL’s Director of European Programs and Director of Development welcomed the largest crowd of registrants in the event’s history.",
        "It continued the next day — a full day of lectures and panels at a former Coca-Cola factory — when David Friedman began speaking about communities in Somalia and Saga-Period Iceland, as well as the Comanche Indians, who all did just fine for themselves without a government. There were maybe seven empty seats, in a hall of 1,000, for his first new talk in more than a decade.",
        "On it went into Saturday night’s VIP dinner, on a grand terrace high above the city, where the speakers, Students For Liberty staff, and its top coordinators gathered in cocktail attire for a Georgian “supra,” or feast. And technically, the event ended with Sunday’s gathering at Lovebar: a circular, outdoor, tree-lined space with multiple stages, sponsor booths, and a restaurant. On one stage were talks about universal basic income, transhumanism, and a screening of a documentary. On another was Reasonfest, which featured talks on Objectivism, identity politics, and a comedy film screening, and whose space was standing-room-only throughout the day.",
        "But the real, true heart of LibertyCon Europe beat the whole week, in the backyard garden terrace of the Fabrika Hostel and Suites, where Students For Liberty’s local coordinators and volunteers stayed. For those five days and nights, it became the modern-day, better-lit, and fresh-aired version of the smoky, dark cafes of Paris and Vienna — cafes where pens toppled walls, where our intellectual heroes debated until dawn. On that terrace, if you looked close enough, you could see the next Hayek, the next Hazlitt, the next Hugo sitting and arguing and thinking — fearlessly and fully accepting the responsibility of spreading the ideas of liberty throughout Europe, and to the whole world.",
      ],
      image: image7,
      id: "Europe 2",
    },
    {
      location: "Europe 3",
      name: "Štěpán Kovář: Even More Events to His Name than Accents in It",
      descr: [
        "Just 21 years old, Štěpán Kovář (pronounced Shtep-an Ko-vash) is nonetheless a fount of energy for liberty. He’s not only the youngest Regional Coordinator in Europe; he’s the most prolific. Under the leadership of this math and IT major from Prague, the Czech SFL team grew from six active volunteers to 21 by the end of Fiscal Year 2023.",
        "In FY 2024, Štěpán parlayed that momentum into 13 events in Prague alone. In particular, his team’s “Legalize It!” event drew more than 160 attendees and his “End the Drug War” booklet designed for the conference has garnered a second edition sponsorship.",
        "And he didn’t forget to spread the word beyond Czechia; videos on the Czech SFL YouTube channel, recorded by Štěpán, amassed over 6,000 views. He also initiated the Bitcoin Activism Project, which included public stunts in front of the central bank with the distribution of educational content to the crowds they drew. In all, he organized or co-organized a total of 30 events, including the (De)Growth Debate, which he moderated. He also spoke at both LibertyCon Europe and LibertyCon International.",
        "Štěpán also partnered with multiple organizations, including Ukrainian Students For Freedom, to help war-torn Ukraine, delivering more than $800,000 of assistance.",
      ],
      image: image8,
      id: "Europe 3",
    },
  ],
  Africa: [
    {
      location: "Africa",
      name: "A Celebration a Decade in the Making",
      descr: [
        "LibertyCon International was far from the only massive, and massively successful, LibertyCon in 2024. In March in Nairobi, Kenya, SFL celebrated 10 years as the continent’s largest student movement for liberty.",
        "With the theme of “Africa Without Borders,” event organizers were modest in their targets for the first in-person African LibertyCon: 350 participants and 1,000 registrations would have been a success. But when all was said and done, the conference brought 412 participants from 10 different countries to the Catholic University of Eastern Africa and attracted 1503 registrations from students and young professionals across the continent.",
        "They listened to 35 panelists, keynote speakers, and SFL coordinators discuss issues that affect Africa. Programming on Day 1 covered issues like free trade, AI and job creation, tax policy, charter cities, and sustainable energy. On Day 2, African Liberty hosted a policy round table, which included sessions on journalism and individual freedom in Africa. Participants and registrants went home with the first edition of the Freedom African Magazine, which was distributed to everyone in attendance.",
      ],
      image: image9,
      id: "Africa",
    },
    {
      location: "Africa 2",
      name: "The Pride of Kenya",
      descr: [
        "Beryl Ooko grew up in a part of Nairobi that tourists don’t visit, where film crews aren’t allowed without a permit. Whatever you call it — “ghetto” or “slums” —  it was rough. But her father, Walter, is a man who believes, “In life, you don’t get to take the escalator; you have to take the stairs.” That’s the spirit he instilled in his daughter, a daddy’s girl from Day 1. And it’s the spirit she brought to her adolescent basketball career and then to university and law school.",
        "Of course, it takes more than spirit — it takes more than intelligence, more than luck — to make a difference in the world. It takes skills. It takes confidence. And that’s what Beryl found in Students For Liberty during her time in law school. She’s naturally quiet; humble; soft-spoken. And those are attractive qualities. But to help lift other Kenyans from poverty the way her family lifted themselves from it, Beryl knew she would have to become more outgoing, and to speak often and speak loudly.",
        "SFL’s public speaking training got her part of the way there. Organizing SFL events and networking helped, too. You should see her now. She’s still humble, still her father’s daughter, but now is a full-fledged lawyer and mentor for law students. And 2024 was her best year yet. She served as MC at LibertyCon Africa in March, and in May, a report she wrote about how Kenyan nonprofits were hamstrung by regulations was read by President William Ruto. He proceeded to enact the Public Benefits Organization Act, which had been stalled in congress for 11 years.",
        "Now, Kenyan nonprofits are nimbler to respond to people and to market incentives. And with an ever-growing confidence in herself, kindled by SFL, Beryl is the person her father always wanted her to be: still spirited, still humble, a little more outspoken, and ferociously dedicated to what she believes in. That’s why he was comfortable moving with his wife and younger kids to the United States, and leaving Beryl behind. Because she’s no longer his little girl; she’s a powerful young woman making a difference for her country. And she’s just getting started in making Kenya a freer place.",
      ],
      image: image10,
      id: "Africa 2",
    },
    {
      location: "Africa 3",
      name: "ASFL: Making Free Trade Cool Again",
      descr: [
        "The most important trade agreement since the WTO was launched in 2021. While 48 countries have ratified the African Continental Free Trade Area, and all 54 African countries have signed it, it has been slow to take root in real-life trade and exchange. That’s due partly to Pandemic-era restrictions, partly to a tradition of protectionism in Africa, and partly because the concept of free trade is a bit in the weeds; it’s not sexy or headline-grabbing.",
        "But Africa Students for Liberty is bringing it out of the weeds and into the headlines. It launched its AFCFTA project in April of 2024 and has raised awareness that much of Africa is now a free trade area, while promulgating the benefits of free trade and why young people should support it. To do this, AFL hosted dozens of webinars that attracted hundreds of students throughout Africa, and over the summer, hosted in-person events on college campuses.",
        "There remains a lot of work to do; ASFL has begun with an initiative to motivate the countries that have ratified the agreement to enact the legislation and policies necessary to implement it. Of course, no one ever said lifting millions of Africans out of poverty would be easy, so ASFL is in it for the long haul: to increase standards of living by increasing trade.",
      ],
      image: image11,
      id: "Africa 3",
    },
  ],
  Eslibertad: [
    {
      location: "EsLibertad",
      name: "Title is missing",
      descr: [
        "Tepic is a city of about 330,000 in central Mexico, the capital of Nayarit — the 28th state in Mexico, established in 1917. As far as its residents could remember, there had never, in Tepic’s or Nayarit’s history, been an official, public event that promoted or discussed liberalism … until one warm afternoon, this past May, when more than 500 students packed into a local soccer stadium to participate in ESLibertad’s Liberty Roadshow.",
        "The Roadshow’s M.O. was simple enough: SFL’s most successful and passionate Mexican coordinators contacted college principals around the country and offered to speak about leadership and entrepreneurship in the liberal world. Schools would arrange a big student group, and usually, between 40 and 80 students attended. In the program’s first two months, it connected with 1,735 students in just the first four states it visited. By the time it was done, the Roadshow had traveled to 15 states.",
        "Students and their professors frequently requested follow-up activities, including virtual sessions, to revisit the topics covered at the Roadshow. And because the Roadshow emphasized rural areas less likely to be familiar with the principles of liberty, SFL’s reputation as Mexico’s only pro-liberty student organization — indeed, its reputation as a route toward a better life for students throughout the country — has never been stronger.",
      ],
      image: image12,
      id: "EsLibertad",
    },
  ],
  MENA: [
    {
      location: "MENA",
      name: "Turning over a New Leaf in Tunisia",
      descr: [
        "In light of increasing restrictions on free speech, Students for Liberty MENA (Middle East and North Africa) organized its inaugural event in Tunisia in early March. This groundbreaking event, orchestrated by SFL's Local Coordinators, gathered 45 participants from Tunisia and Algeria. They united with a shared commitment to exploring and advocating for the fundamental principles of free speech.",
        "The event featured a series of discussions about the nuances of cultural sensitivities and the crucial role of free speech in fostering an open and democratic society. Participants shared their perspectives, experiences, and insights in open and interactive sessions. One highlight was the panel where experts from throughout the liberty landscape spoke from experience about the current state of free speech in Tunisia and Algeria, the challenges faced by advocates, and strategies the participants could employ to protect and promote this essential right. Their contributions sparked thoughtful debates and encouraged participants to think critically about the intersection of cultural norms and freedom of expression.",
        "The successful event in Tunisia emboldened SFL MENA, even in a year that has been hostile to the ideas of liberty throughout the region, to host a conference in Morocco in October. That event featured three partner organizations and included policy recommendations from experts on the subject of information migration to Morocco.",
      ],
      image: image13,
      id: "MENA",
    },
    {
      location: "MENA 2",
      name: "Amjad Aun: Everything Students For Liberty Is About, and More",
      descr: [
        "On the overcast Saturday afternoon of LibertyCon International, Amjad Aun stood on the corner of 10th and H St. in Washington, D.C., looking off into the distance. His flight from Germany had landed only 72 hours earlier, and he had been going, nonstop, ever since: meeting people, listening to lectures, volunteering to carry equipment and decorations; anything to help the event succeed. This moment of tranquility must have been his first since he arrived.",
        "And even in that moment, he was thinking about how to make the future freer. “I’m thinking about what I can really do for Syria. I grew up there, I love it there … but all the political parties are either communist or socialist. And then there’s the religious ideologies. I’m part of the minority, and sometimes it feels hopeless. My family lost most of its wealth because of the war. So I’ve just been asking around: ‘How can I do more?’”",
        "He’s answering that question with action. In the past year, since starting to pursue a PhD in the economics of science in Germany, his career as an academic has taken off; he’s established himself as a rising star in his field with the prestigious German Research Foundation. But just as importantly, in his appearances at LibertyCons International and Europe, the Prometheus Fellowship Summit, and other events, Amjad has established himself as one of SFL’s best, most well-respected coordinators. He’s done it with good old-fashioned hard work, humility, and thoughtfulness.",
        "In the true tradition of individualism, though, he’s also done it by looking inward. “I want to make myself into the best person, and best communicator, I can be,” he said. “Reading books nurtures the mind, and finding a community where you can discuss what you read and deepen your understanding nurtures the soul. That’s why SFL is one of the greatest things that happened to me. It gave me the knowledge in online webinars, in-person seminars, networking opportunities with significant economists. But then, SFL gave me what’s even more important: a home, a safe zone, and a second family that provides support and care and feedback. And now, I am even more motivated to build a career in academia and become a donor and supporter of SFL myself, so I can help SFL do for other people what it did for me.”",
      ],
      image: image14,
      id: "MENA 2",
    },
  ],
  "South Asia": [
    {
      location: "South Asia",
      name: "The Ambulance Ride of His Life",
      descr: [
        "Rashid Shahriar had emerged as a transformational leader within Students For Liberty long before he risked his life for the cause. From humble roots in Jamalpur, Bangladesh, Rashid became not only a scholar, but an activist — one who learned, and learned how to adapt, with SFL. In the last year, he has spearheaded 13 events, co-organized major initiatives like the SFL Youth Carnival, and played a pivotal role in establishing and strengthening SFL clubs in four new towns in his home country.",
        "These efforts have revived SFL in Bangladesh. From a mere 15 Coordinators in 2021, at the height of pandemic restrictions and lockdowns, the Bangladesh team now consists of 61 carefully selected SFL Coordinators. Overall, Bangladesh has reported 79 events with a whopping 2133 participants in the last fiscal year, and much of this success is due to Rashid’s initiative and networking skills. He secured $450 from the Friedrich Naumann Foundation and equipped SFL Bangladesh with more than $1,000’s worth of free venues across the country. Most importantly, at a critical time in the country’s history (during the National Parliament elections), the Bangladesh Classical Liberal Conference hosted more than 600 participants and received more than $4,500 in in-kind donations, winning SFL’s Global Event of the Year Award for 2023 in the process.",
        "Rashid’s skills as a video producer have helped at every step along the way. His highlight videos of events and retreats attracted young people to ideas like free markets and free speech and brought them into the SFL ecosphere. Those skills earned him a scholarship to pursue a degree in Documentary Production at the University of North Texas.",
        "Rashid was on his way to Dallas to start the program, and was well on his way to a nomination for SFL’s Student of the Year Award … and then had to risk his life just to escape Bangladesh. Amidst widespread protests and an unjust and murderous “shoot-on-sight” military curfew, Rashid hired an ambulance driver to take him to the airport. He flew to Nairobi for the Prometheus Fellowship Summit, then to the U.S., where he is honing his video production skills and fast becoming one of the strongest advocates for liberty in the world. Because, well, he can tell you from personal experience what can happen if you don’t fight for freedom.",
      ],
      image: image15,
      id: "South Asia",
    },
    {
      location: "South Asia 2",
      name: "Peshawar Group, National Influencers Unite in the Heart of Hostility",
      descr: [
        "Anyone promoting freedom, anywhere within shouting distance of Taliban-ruled Afghanistan, would face an uphill climb, but SFL Peshawar has emerged as a shining example of grassroots activism and fundraising for liberty. This small but determined unit has organized more than 100 events that have drawn more than 2,000 participants in all. SFL Peshawar also produced three Prometheus Fellows and one of the founders of the group co-founded the National Influencers (NI). In partnership with SFL Peshawar, NI has translated three Institute of Economic Affairs books by British economist Eamonn Butler into the vernacular Urdu language: Foundations of a Free Society; An Introduction to Trade and Globalization; and An Introduction to Democracy. NI wasn’t content merely to translate, though; they held 15 reading groups — known as study circles — to encourage members to read and understand the books.",
        "NI also hosted an event called the KP Business Plan Competition. This Shark Tank-like event provided an opportunity for aspiring student entrepreneurs to showcase their innovative ideas and receive feedback from industry experts. The winners received small start-up grants which could only be redeemed if they actually registered and set up a for-profit business entity.",
        "Another focus of SFL Peshawar over the past year has been on Ayn Rand’s philosophy: Objectivism. Aligning with SFL’s New Frontiers of Objectivism campaign, the group organized 34 separate events dedicated to fostering a deeper understanding of Objectivism’s principles and applications.",
        "This group’s efforts have raised awareness and empowered individuals to embrace freedom, entrepreneurship, Bitcoin, free-market environmentalism, and limited government. And there’s much more to come: Because media outreach has yielded more than 1500 Local Coordinator program applications over the past fiscal year, an SFL expansion not just in the city of Peshawar, but throughout central and southern Pakistan, is on the way.",
      ],
      image: image16,
      id: "South Asia 2",
    },
    {
      location: "South-Asia 3",
      name: "One Billion Indians to Help, One Million Dollars to Do It. So Far",
      descr: [
        "Sexual health is often a taboo topic, but Akash Kumar thought of it differently: To him, “taboo” meant “market gap,” and “unmet need.” With the networking and finance skills he honed with Students For Liberty, Akash created Raaz — a healthcare portal and app that connects Indian men with doctors who can help. It’s more than that, even; Raaz represents India’s go-to platform for sexual resources in the vernacular Hindi language. And during the week of the Prometheus Fellowship Summit in Nairobi, Akash closed in on the million-dollar mark for money raised, while the company is now valued at close to $4 million.",
        "“The rapid decline in fertility rates across the world is a cause of deep disarray to those of us who believe in abundance and human flourishing,” he said. “It’s even more concerning for India, as it risks becoming old before it becomes rich. Building Raaz is my small attempt at reversing that and helping couples have children while upholding individual choice.”",
        "Akash’s introduction to SFL inspired him to read the works of Israel Kirzner, Joseph Schumpeter, F.A. Hayek, and John Mackey. He learned from those writers how crucial the role of the entrepreneur is in society, and that role is what keeps him motivated. “That’s what gets me out of bed,” he said. “The urge to find solutions to even the smallest of problems our team or customers are facing. And what I learned from the great Austrian economists, Hayek and Kirzner specifically, is that the profit that comes is just the signal — the way you know you’re creating value and solving real problems that real people have.”",
        "He expounds on this idea in the book he co-authored, which will be published in 2025 by the Hachette Book Group. It’s an economics primer targeted at Indian adults, and Akahsh hopes it will inspire thousands of them to identify more unmet needs, in the way he did. And, he hopes, it will spark social change — in the way Hayek suggested it should happen.",
      ],
      image: image17,
      id: "South-Asia 3",
    },
  ],
  Brazil: [
    {
      location: "Brazil",
      name: "SFL Shows Up and Shows Out in Election Cycle",
      descr: [
        "For much of Students For Liberty’s life, Brazil has represented the future: an ever-growing network of coordinators and alumni that, one day, would turn one of the world’s fastest-growing nations into a land of liberty. That future has arrived.",
        "During this past fall’s election cycle, 22 SFL alumni ran for office in their city legislatures. Eight won their races and earned places in their local governments. One of them, Marcela Trópia, won re-election with the fifth-highest vote total out of 41 winners in Belo Horizonte, a city of more than 2 million people. She did so partly on the strength of her committee to “de-bureaucratize” the economic sector in Belo Horizonte. In making this initiative the tentpole of her candidacy, she said, The big problem is the excess of bureaucracy that citizens encounter when they need to interact with the public sector. We need to discuss and propose actions that simplify procedures in public bodies, in addition to repealing useless laws.",
        "Meanwhile, in the state of Pará in northern Brazil, both candidates with ties to SFL were elected. Nationwide, SFLers received 784,297 votes. But those numbers don’t encapsulate the general engagement throughout the organization’s network. Look no further than those 22 candidates’ campaign staffs; most of them comprised SFL coordinators and alumni.",
      ],
      image: image18,
      id: "Brazil",
    },
    {
      location: "Brazil 2",
      name: "Seeds of Freedom",
      descr: [
        "Plants need water, soil, and sunlight. So do ideas. And one of the ways Students For Liberty Brazil has built on its momentum is with an initiative known as the Coordinator Journey Incubator. It’s a “greenhouse of ideas,” where people gather to watch and take care of each other’s idea seedlings.",
        "Coordinators can network with alumni, who volunteer to act as coaches; coordinators can workshop and share ideas amongst each other to develop new skills; and SFL and its alumni provide funding to put the best ideas into action. The Incubator is designed to showcase the adaptability, resilience, and impact of Coordinators’ projects, and to help SFL’s Programs team decide which are most worthy of extra support from its network.",
        "In the past year, the Programs team received 45 applications — an increase of more than a third, even as election season attracted the attention of many pro-liberty Brazilians. Therefore, the team has been able to support projects like LOLA (Ladies of Liberty Alliance) Brazil, which mentored 30 women. Additionally, Empreendedores do Amanhã (Entrepreneurs of Tomorrow), a contender for SFL’s Event of the Year Award, hosted a day-long immersion into entrepreneurship and liberty in the city of Porto Alegre. More than 100 young people in dire socio-economic situations attended. When they left, they did so with more hope for the future and more intellectual resources to attack it than they’ve ever had.",
        "One further project the SFLB team has incubated is Lux Futuri (Light of the Future in Latin), an educational lecture series. It began in 2022, when Rafael Mendonça, from Juiz de Fora in the southeast of Brazil, noticed that the National Common Curriculum Base didn’t cover topics like basic economics and financial education. His humble aim, when he brought the seed of this as-yet-unnamed idea to the Incubator, was to give a few lectures in a few schools in his hometown. But by 2024, Rafael had given 36 lectures in his state, and spoken to more than 1,900 students total. And now, Lux Futuri is preparing to launch an online course to train more speakers who will visit more schools throughout the country.",
      ],
      image: image19,
      id: "Brazil 2",
    },
  ],
};

export default stories;
