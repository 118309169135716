import CountUp from "react-countup";

import "./finances.css";

const FinanceCountUp = ({ start = 0, end = 1, delay = 0.5, duration = 5 }) => (
    <CountUp
        start={start}
        end={end}
        delay={delay}
        duration={duration}
        prefix="$"
        enableScrollSpy={true}
        scrollSpyOnce={true}
    >
        {({ countUpRef }) => (
            <div>
                <span className="number-one" ref={countUpRef} />
            </div>
        )}
    </CountUp>
);

export default FinanceCountUp;
