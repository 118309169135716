import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";

import Btn from "../Btn/Btn";

import report from "../../assets/images/annual-report.webp";
import "./report.css";

const Report = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();

    animateTitle1(titleRef.current);
  }, []);
  return (
    <section className="finances-report bg-yellow border-radius-35">
      <div className="container">
        <h3 ref={titleRef} data-splitting>
          Download Annual Report
        </h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
        </p>
        <Btn text={"GET REPORT IN PDF"} />
        <img src={report} alt="Annual Report" />
      </div>
    </section>
  );
};

export default Report;
