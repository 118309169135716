import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";

import { animateTitle1 } from "../../helpers/animateTitle";
import RevenueChart from "../InteractiveChartOne/InteractiveChartOne";
import ExpensesChart from "../InteractiveChartTwo/InteractiveChartTwo";
import Report from "../Report/Report";
import FinanceCountUp from "./FinanceCounter";

import { expenses, revenue } from "../../resources/finances";
import "./finances.css";

const Finances = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();

    animateTitle1(titleRef.current);
  }, []);

  return (
    <>
      <section className="finances bg-white">
        <div className="container">
          <h2>Students For Liberty</h2>
          <h3 ref={titleRef} data-splitting>
            Finances
          </h3>
          <div className="finances-content-wrapper">
            <div className="finance-card">
              <h4>Revenue</h4>
              <div className="finance-card-content-wrapper">
                <div className="finances-card-header bg-yellow">
                  <FinanceCountUp end={5196568} />
                  <span className="icon-Icons_Revenue icomoon"></span>
                </div>
                <RevenueChart />
              </div>
              <div className="finance-card-chart-descr">
                <ul>
                  {revenue.map(revenueItem => (
                    <li key={revenueItem.id}>
                      <p>{revenueItem.label}</p>
                      <span className="percentage">{revenueItem.percentage}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="finance-card">
              <h4>Expenses</h4>
              <div className="finance-card-content-wrapper ">
                <div className="finances-card-header bg-yellow">
                  <FinanceCountUp end={7004850} />
                  <span className="icon-Icons_Expenses icomoon"></span>
                </div>
                <ExpensesChart />
              </div>

              <div className="finance-card-chart-descr">
                <ul>
                  {expenses.map(expense => (
                    <li key={expense.id}>
                      <p>{expense.label}</p>
                      <span className="percentage">{expense.percentage}</span>

                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Report />
    </>
  );
};

export default Finances;
