import { Route, Routes } from "react-router-dom";

import Home from "../../pages/Home";
import SingleStorie from "../../pages/SingleStorie";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/single-storie/:id" element={<SingleStorie />} />
      </Routes>
    </>
  );
};

export default App;
