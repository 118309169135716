import React, { useState } from "react";
import "./Chart.css";

import { expenses as segments } from '../../resources/finances';

const InteractiveChartTwo = () => {
  const [hoveredSegment, setHoveredSegment] = useState(1);

  const handleMouseEnter = (id) => {
    setHoveredSegment(id);
  };

  const handleMouseLeave = () => {
    setHoveredSegment(1);
  };

  const activeSegment = segments.find(
    (segment) => segment.id === hoveredSegment
  );

  return (
    <div className="chart-container-two chart-svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 213.56 212.84"
        className="chart-svg"
      >
        <g>
          <path
            d="M170.19,62.5c8.58,11.68,13.6,25.57,14.48,40.03l-75.26,4.59,60.77-44.63Z"
            fill="#85c1c7"
            className={hoveredSegment === 3 ? "segment-hover" : ""}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          />
          <path
            d="M187.71,104.52c.84,13.75-2.11,27.48-8.52,39.68l-66.74-35.08,75.26-4.59Z"
            fill="#eec499"
            className={hoveredSegment === 2 ? "segment-hover" : ""}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          />
          <path
            d="M172.15,142.51c-19.37,36.86-64.96,51.03-101.82,31.65-36.86-19.38-51.03-64.97-31.66-101.82,19.37-36.86,64.96-51.03,101.81-31.65,10.13,5.33,18.92,12.89,25.69,22.11l-60.77,44.63,66.74,35.08Z"
            fill="#d8c1d9"
            className={hoveredSegment === 1 ? "segment-hover" : ""}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          />
        </g>

        <text
          className="svg-number"
          x="75"
          y="100"
          textAnchor="middle"
          fontSize="16"
          fill="#000"
        >
          {activeSegment.percentage}
        </text>
        <text
          className="svg-text"
          x="75"
          y="110"
          textAnchor="middle"
          fontSize="7"
          fontWeight="normal"
          fill="#000"
        >
          {activeSegment.label}
        </text>
      </svg>
    </div>
  );
};

export default InteractiveChartTwo;
