import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css/bundle";
import "./slider-quotes.css";
import quote from "../../assets/icons/quote.png";
import quotesSlides from "../../resources/sliderQuotes";

const SliderQuotes = () => {
  return (
    <Swiper
      modules={[Pagination, Navigation]}
      loop={true}
      centeredSlides={true}
      navigation={true}
      pagination={{ clickable: true }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
          centeredSlides: false,
        },
        700: {
          slidesPerView: 2,
          spaceBetween: 20,
          centeredSlides: false,
        },
        1200: {
          slidesPerView: 3.5,
          spaceBetween: 30,
        },
        1440: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      }}
    >
      {quotesSlides.map((slide, i) => (
        <SwiperSlide key={i} className="slider-quotes border-radius-20">
          <div className="quotes-content-wrapper">
            <img src={quote} className="quote-icon" alt="Quote" />
            <p className="quotes-descr">"{slide.descr}"</p>
            <div className="quotes-speaker-info">
              <p>
                <b>— {slide.name}</b>
              </p>
              <p className="institute">{slide.institute}</p>
            </div>

            <img
              className="quotes-speaker-thumb"
              src={slide.thumbnail}
              alt="Testimonial"
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderQuotes;
