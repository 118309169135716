import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";
import stories from "../../resources/stories";
import Btn from "../Btn/Btn";

import "./global-presence.css";

const GlobalPresence = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();
    animateTitle1(titleRef.current);
  }, []);

  const [activeTab, setActiveTab] = useState("North America");

  const tabs = {
    "North America":
      "SFL chapters champion student rights, free speech, and host LibertyCon in D.C.",
    "Asia Pacific":
      "SFL chapters champion student rights, free speech, and host LibertyCon in D.C.",
    Europe:
      "SFL chapters champion student rights, free speech, and host LibertyCon in D.C.",
    Africa:
      "SFL chapters champion student rights, free speech, and host LibertyCon in D.C.",
    Eslibertad:
      "SFL chapters champion student rights, free speech, and host LibertyCon in D.C.",
    MENA: "SFL chapters champion student rights, free speech, and host LibertyCon in D.C.",
    "South Asia":
      "SFL chapters champion student rights, free speech, and host LibertyCon in D.C.",
    Brazil:
      "SFL chapters champion student rights, free speech, and host LibertyCon in D.C.",
  };

  const activeTabContent = stories[activeTab];

  return (
    <section className="global-presence bg-grey" id="global-presence">
      <div className="container">
        <h2>around the globe</h2>
        <h3 ref={titleRef} data-splitting>
          Transforming the World:
          <br /> Our Latest Stories by Regions
        </h3>
        <div className="globe-info">
          <p>SFL is present in</p>
          <span>109</span>
          <p>countries around the globe</p>
          <span className="icon-Icons-07 icomoon"></span>
        </div>
        <div className="tabs-wrapper">
          <ul className="tabs">
            {Object.entries(tabs).map(([key, value]) => (
              <li
                key={key}
                onClick={() => setActiveTab(key)}
                className={activeTab === key ? "active" : ""}
              >
                <span>{key}</span>
                <p>{value}</p>
              </li>
            ))}
            <li className="info">
              <p>SFL is present in</p>
              <span>109</span>
              <p>countries around the globe</p>
              <span className="icon-Icons-07 icomoon"></span>
            </li>
          </ul>

          <div className="tab-content">
            {activeTabContent.map((item) => (
              <div className="tab-card border-radius-20" key={item.name}>
                <div className="tab-card-img-wrapper">
                  <img src={item.image} alt="storie-thumbnail" />
                </div>
                <div className="tab-card-content-wrapper">
                  <span>{item.location}</span>
                  <h4>{item.name}</h4>
                  <p>{item.descr[0]}</p>
                  <Link to={`/single-storie/${activeTab}-${item.location}`}>
                    Read more ›
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Btn text="More Stories" />
    </section>
  );
};

export default GlobalPresence;
