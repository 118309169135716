import dan from "../assets/images/Dan-Grossman.webp";
import gabriel from "../assets/images/Gabriel-Calzada.webp";
import john from "../assets/images/John-Mackey.webp";
import katherine from "../assets/images/Katherine-Mangu-Ward.webp";
import liya from "../assets/images/Liya-Palagashvili.webp";
import rick from "../assets/images/Rick-Rule.webp";
import sam from "../assets/images/Sam-Eckman.webp";
import sloane from "../assets/images/Sloane-Frost.webp";
import wayne from "../assets/images/Wayne-Olson.webp";



const directors = [
    {
        name: "Dan Grossman",
        position: "CHAIRMAN Retired Businessman",
        thumbnail: dan,
    },
    {
        name: "Gabriel Calzada",
        position: "Mont Pelerin Society, President",
        thumbnail: gabriel,
    },
    {
        name: "Sam Eckman",
        position: "Gibson, Dunn & Crutcher LLP, Associate",
        thumbnail: sam,
    },
    {
        name: "Sloane Frost",
        position: "Medical Home Network, Manager of Strategic Initiatives",
        thumbnail: sloane,
    },
    {
        name: "John Mackey",
        position: "Whole Foods Market, Co-Founder and Former CEO",
        thumbnail: john,
    },
    {
        name: "Katherine Mangu-Ward",
        position: "Reason Magazine, Editor in Chief",
        thumbnail: katherine,
    },
    {
        name: "Wayne Olson",
        position: "Retired investment banking executive",
        thumbnail: wayne,
    },
    {
        name: "Liya Palagashvili",
        position:
            "Senior Research Fellow, Mercatus Center, George Mason University",
        thumbnail: liya,
    },
    {
        name: "Rick Rule",
        position: "Global Resource Investments, Founder",
        thumbnail: rick,
    },
];

export default directors;