const featuredStories = [
    {
        type: "global",
        title: "The Human Heart Wants to be Free",
        shortDescription:
            "Although Western democracies are not truly free, students here enjoy relative freedom. So, in many cases, the students who live in other parts of the world best demonstrate how desperately the human heart yearns to be free; they often risk their very lives to discuss the ideas of John Locke, Adam Smith, and others. A comprehensive list of the perilous but powerful work Students For Liberty are doing in the face of Asian and South American dictatorships is not possible to include here, but these examples suffice.",
        description: [
            "Bangladesh: Ever since its war for independence with Pakistan in 1971, Bangladesh has faced political instability, authoritarianism, and widespread poverty. And yet, SFL’s coordinators there have hosted important and successful events, including the Bangladesh Classical Liberal Conference, which won SFL’s Event of the Year award. In one particularly breathtaking act of civil disobedience and dedication to liberty, Rashid Shahriar escaped from Bangladesh during a military-imposed 'shoot-on-sight' curfew — in an ambulance — to attend an SFL conference in Kenya.",
            "Afghanistan: Wracked by war for over a century, Afghanistan has recently suffered at the hands of the Taliban, which has violently repressed personal freedoms and rights, particularly for women. However, SFL Peshawar has organized more than 100 classical liberal events that have welcomed more than 2,000 total participants — many of whom have risked their safety to cross the Afghanistan border into Pakistan.",
            "Cambodia: A mere 50 years after the killing fields of Pol Pot’s murderous Khmer Rouge regime, and despite the current communist ruling party’s best efforts, one brave Cambodian SFL Coordinator hosted the first-ever libertarian event at her university.",
            "Venezuela: A once-thriving country, Venezuela’s recent history has been marked by economic pillaging and political repression under the leadership of Hugo Chávez and Nicolás Maduro. The result: a severe humanitarian crisis and the erosion of civil liberties. However, 18 Venezuelan SFL coordinators have hosted 241 events, welcoming more than 3,400 people in total. Despite the fear of political persecution or imprisonment amid a government crackdown on such events, those coordinators have advanced their belief in free markets and limited government and posed a direct challenge to the socialist regime’s messaging.",
            "Ukraine: As soon as the Russian invasion of Ukraine started, SFLers leaped into action; our students did not wait to be told what to do. They drove to the border to take in refugees and provided them with shelter, and transfer of goods and materials. And after a 2-year hiatus for the war, SFL has returned to Ukraine to share the lessons of classical liberalism with new students for whom these ideas are no longer theoretical, but a matter of life and death.",
            "Myanmar: Before the 2021 coup, SFL Myanmar was active in spreading the ideas of libertarianism — especially at the University of Yangon. During the coup, however, SFL Myanmar’s protest of the military government resulted in the arrest of one of its students. He was released, but the ordeal forced the group to tone down its operations. However, over the past year, one student — Toe Lwin Shein — has revived SFL Myanmar through Free Market Environmentalism. His initiative, Green Frontier Myanmar, represents bold steps in a place where authoritarianism has been prevalent in environmental policy. His work to navigate a dangerous and complex political, social, and economic landscape is ongoing.",
        ],
    },
    {
        type: "united states",
        title: "Proving the Power of the Pen",
        shortDescription:
            "They say journalism is dead … but Jack Nicastro is proof: It’s not going anywhere. (It just needed a fresh coat of paint.) Jack took over as House Coordinator for House Hazlitt in the summer of 2023; it’s Students For Liberty’s professional development track for Journalism and Content Creation. Jack’s mission was to show that the pen is still mightier than the sword. But he wasn’t sure how.",
        description: [
            "With his background as scriptwriter at FEE, Jack took on the role of Editor-in-Chief and started recruiting writers from SFL’s broad network of students and thought leaders. He inspired Amjad Aun to explore the struggles of classical liberals in Syria; Padraig Quinn to examine the complexities of commerce and taxation; and Juan Estevez to advocate for more private cities.",
            "All the while, Jack was securing sponsorships. He raised almost $5,000 from Reason, Mercatus, and FEE, among other prestigious organizations, for the first edition. So LockeSmith had financial independence; a dynamic editorial vision; and a talented staff of writers. All that was left … was to launch. 1,500 copies were printed for circulation at LibertyCon International in February 2024. By the end of the conference, every copy had been distributed.",
            "So, he went back to his roots as a classical liberal … to his heroes: John Locke and Adam Smith. Above all, he tapped into what compelled him to join Students For Liberty at Dartmouth University in 2021: lockdowns — of people, and of ideas. Mandates — of masks and of obedience. 'It was, ultimately, free speech that was under attack,' he said. 'And I’m someone who likes to speak. You can’t take that away from me without a fight.'",
            "Inspired by Ethan Yang, whose Freedom of Information request led to a Supreme Court case — Murthy v. Missouri — that challenged the government’s suppression of speech, Jack’s fight began in earnest. 'Ethan showed so much courage that I had to make him proud. While he was fighting in the courts, I wanted to fight on the battlefield of ideas,' Jack said.",
            "As the 2023 school year began, that fight began to take shape. In his role with House Hazlitt, he embarked on an ambitious project: to create an impactful classical liberal magazine. To do so, Jack knew it had to reflect the broad spectrum of libertarian thought and spark discussion by exploring controversial topics. Enter: LockeSmith.",
        ],
    },
    {
        type: "SFL alumni",
        title: "The Future has Arrived",
        shortDescription:
            "There’s no way around it: To be a Students For Liberty alumnus, you need to have shown an understanding of and dedication to the ideas of liberty. And because SFL has alumni in positions of power — both in governments and the private sector — on all six habitable continents, those ideas are permeating societies around the world. Again, the list below is not comprehensive, but it suffices to prove that SFL alumni are leading the charge for a better tomorrow.",
        description: [
            "Trace Mitchell is General Counsel to Senator Rand Paul and former Senior Counsel to the House Judiciary Committee. He has recently published articles in leading outlets like the Washington Examiner and Reason.",
            "Gianluca Lorenzon became a full-time investment banker at Goldman Sachs after graduating with an MBA from Stanford University. In that position, he will reaffirm his belief in the principles of liberty and capitalism — principles he learned with Students For Liberty and implemented in Brazil. There, he created, wrote, and helped implement the Brazilian Economic Freedom Act, guaranteeing economic liberty. It eliminated the need for more than 10 million licenses for small businesses.",
            "Dr. Vera Kichanova has been focused, at each step of her career, on bridging the gap between urban planning theory and classical liberal thought. She is currently a Senior Researcher at Zaha Hadid Architects and a Senior Economist at the Free Cities Foundation. Earlier in life (age 20), she was elected to the Moscow Municipal Council and was one of the youngest elected officials in the country. Her PhD thesis at King’s College London explored the future of private urban development — from charter cities in emerging economies to start-up nations in neutral waters.",
            "Ethan Yang filed the pivotal Freedom of Information Act request that became the cornerstone of the Murthy v. Missouri Supreme Court case, in which one judge called the Biden Administration’s suppression of information 'The most massive attack against free speech in U.S. history.' Additionally, during FY 2024 he served as Legal Associate at the Cato Institute and co-authored the book *The China Dilemma: Rethinking US-China Relations Through Public Choice Theory.*",
            "César Báez spent four years as an SFL activist, making short videos for social media about economics and liberty. In Venezuela, where traditional media has been co-opted by the government, it was dangerous work. But the quality of that work and the principles behind his content caught the attention of Reason Magazine, which invited him to join their team as a video producer in Washington, D.C. His content now reaches more than 3 million unique viewers per month.",
            "Christopher Barnard is President of the American Conservation Coalition, the largest conservationist organization in North America and the largest youth-based environmental organization in the world. With more than 50,000 members, it is a critical force in awakening the Republican Party to environmental issues and even managed to get a market-based climate change topic on the agenda of the Republican Presidential Primary Debate late last year.",
        ],
    },
];

export default featuredStories;