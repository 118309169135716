import Slider from "react-infinite-logo-slider";

import "./logo-slider.css";
import logo1 from "../../assets/images/media-logos/1_MSN Logo.svg";
import logo2 from "../../assets/images/media-logos/2_Fox_News_Channel_logo.svg";
import logo3 from "../../assets/images/media-logos/3_AOL.com_idv8sM3GaO_1.svg";
import logo4 from "../../assets/images/media-logos/4_Yahoo lifestyle.webp";
import logo5 from "../../assets/images/media-logos/5_ Infobae Logo.svg";
import logo6 from "../../assets/images/media-logos/6_Yahoo News.svg";
import logo7 from "../../assets/images/media-logos/7_Reason_logo.svg";
import logo8 from "../../assets/images/media-logos/8_the-independent-logo.svg";
import logo9 from "../../assets/images/media-logos/9.svg";
import logo10 from "../../assets/images/media-logos/10.svg";
import logo11 from "../../assets/images/media-logos/11_CoinMarketCap_idAhTkmkVa_1.svg";
import logo12 from "../../assets/images/media-logos/12_Newsweek_idLX9cKMAi_1.svg";
import logo13 from "../../assets/images/media-logos/13_TradingView.svg";
import logo14 from "../../assets/images/media-logos/14_cointelegraph-logo-vector.svg";
import logo15 from "../../assets/images/media-logos/15_binance.svg";
import logo16 from "../../assets/images/media-logos/16_The Epoch Times.svg";
import logo17 from "../../assets/images/media-logos/17_Phillipine Inquirer_logo.svg";
import logo18 from "../../assets/images/media-logos/18_National_Enquirer_logo_2014.svg";
import logo19 from "../../assets/images/media-logos/19.svg";
import logo20 from "../../assets/images/media-logos/20.svg";
import logo21 from "../../assets/images/media-logos/21_Fox_8_logo_2020.webp";
import logo22 from "../../assets/images/media-logos/22.svg";
import logo23 from "../../assets/images/media-logos/23_Street-Insider.png";
import logo24 from "../../assets/images/media-logos/24_KXAN_Austin_News_logo.png";
import logo25 from "../../assets/images/media-logos/25.svg";
import logo26 from "../../assets/images/media-logos/26.svg";
import logo27 from "../../assets/images/media-logos/27_WXIN.jpg";
import logo28 from "../../assets/images/media-logos/28_reuters--theme-light.svg";
import logo29 from "../../assets/images/media-logos/29.svg";
import logo30 from "../../assets/images/media-logos/30_WGHP_Fox_8_News_logo.png";
import logo31 from "../../assets/images/media-logos/31_Kdvr-primary.webp";
import logo32 from "../../assets/images/media-logos/32.svg";
import logo33 from "../../assets/images/media-logos/33.svg";
import logo34 from "../../assets/images/media-logos/34_Jornal_do_Commercio.png";
import logo35 from "../../assets/images/media-logos/35_ghanaweb-logo.png";

const logoGroups = [
  [logo1, logo2, logo3],
  [logo4, logo5, logo6],
  [logo7, logo8, logo9],
  [logo10, logo11, logo12],
  [logo13, logo14, logo15],
  [logo16, logo17, logo18],
  [logo19, logo20, logo21],
  [logo22, logo23, logo24],
  [logo25, logo26, logo27],
  [logo28, logo29, logo30],
  [logo31, logo32, logo33],
  [logo34, logo35, logo1],
];

const LogoSlider = () => {
  return (
    <div className="logo-slider">
      <Slider
        width="200px"
        duration={40}
        blurBorders={true}
        blurBorderColor={"#f1f1f1"}
      >
        {logoGroups.map((logos, index) => (
          <Slider.Slide key={index} className="slider-slide">
            {logos.map((logo, i) => (
              <img key={i} src={logo} alt={`Logo ${index * 3 + i + 1}`} />
            ))}
          </Slider.Slide>
        ))}
      </Slider>
    </div>
  );
};

export default LogoSlider;
