import connor from "../assets/images/connor.webp";
import leticia from "../assets/images/leticia.webp";
import gerreth from "../assets/images/gerreth.webp";

const quotesSlides = [
    {
        descr:
            "Students For Liberty is a life-changing opportunity for young leaders passionate about freedom. It's a network of like-minded people and professionals, a career jumpstart, and endless opportunities. I know of no other organizations that empower their volunteers to pursue what drives them and achieve more than they thought possible. I can’t wait to see what my peers and I accomplish next!",
        thumbnail: connor,
        name: "Connor Sutton",
        institute: "SFL North America",
    },
    {
        descr:
            "Joining Students For Liberty kickstarted my career. I gained valuable networking, joined life-changing programs and projects, and even won a global debate competition. SFL is a transformative experience with people from all around the globe.",
        thumbnail: leticia,
        name: "Letícia Barros",
        institute: "SFL Brazil",
    },
    {
        descr:
            "I don’t think I would be the same person without the experiences I’ve had from SFL and the Local Coordinator program. I have met people from Canada, Europe, South America, Asia, and Africa, all of whom were open to discussing almost anything. I know of no other student organization with a community like Students For Liberty.",
        thumbnail: gerreth,
        name: "Garreth Conner",
        institute: "SFL North America",
    },
    {
        descr:
            "Students For Liberty is a life-changing opportunity for young leaders passionate about freedom. It's a network of like-minded people and professionals, a career jumpstart, and endless opportunities. I know of no other organizations that empower their volunteers to pursue what drives them and achieve more than they thought possible. I can’t wait to see what my peers and I accomplish next!",
        thumbnail: connor,
        name: "Connor Sutton",
        institute: "SFL North America",
    },
    {
        descr:
            "Joining Students For Liberty kickstarted my career. I gained valuable networking, joined life-changing programs and projects, and even won a global debate competition. SFL is a transformative experience with people from all around the globe.",
        thumbnail: leticia,
        name: "Letícia Barros",
        institute: "SFL Brazil",
    },
    {
        descr:
            "I don’t think I would be the same person without the experiences I’ve had from SFL and the Local Coordinator program. I have met people from Canada, Europe, South America, Asia, and Africa, all of whom were open to discussing almost anything. I know of no other student organization with a community like Students For Liberty.",
        thumbnail: gerreth,
        name: "Garreth Conner",
        institute: "SFL North America",
    },
];

export default quotesSlides;