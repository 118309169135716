import { useState } from "react";
import "./featured-story-item.css";

const FeaturedStoryItem = ({ title, description, type, shortDescription }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleReadMoreClick = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };
  return (
    <div
      className={`${type
        .split(" ")[0]
        .toLowerCase()} story-item border-radius-20`}
    >
      <div className="content-wrapper">
        <h2>{type}</h2>
        <h3>{title}</h3>
        <p>{shortDescription}</p>
        <div className={`expandable-text ${isExpanded ? "expanded" : ""}`}>
          {description.map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
          ))}
        </div>
        <a href="#" onClick={handleReadMoreClick}>
          {isExpanded ? "Read less ‹" : "Read full story ›"}
        </a>
      </div>
    </div>
  );
};

export default FeaturedStoryItem;
