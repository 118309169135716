import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";

import image from "../../assets/images/dr-wolf.webp";
import signature from "../../assets/images/wolf-signature.webp";
import "./letter-from-ceo.css";

const LetterFromCEO = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();

    animateTitle1(titleRef.current);
  }, []);

  const handleReadMoreClick = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="letter-from-ceo bg-grey">
      <div className="container">
        <div className="left-wrapper">
          <h2>Letter from CEO</h2>
          <h3 ref={titleRef} data-splitting>
            Where liberty thrives, human hearts flourish
          </h3>
          <p>
            <b>Dear Friends of Liberty,</b>
          </p>
          <p>
            We live in an age where trust in institutions is crumbling—and for
            good reason. The media and government have lied to us time and
            again.
          </p>
          <p>Good.</p>
          <p>
            But let us not fall into the trap of believing that liberty’s
            challenges are over simply because the veil of deception has been
            lifted.
            <b>
              Economic illiteracy still runs rampant—in Washington and on
              university campuses alike.
            </b>
            That’s why we’re here. To fight! To persevere! To win!
          </p>

          <div className={`expandable-text ${isExpanded ? "expanded" : ""}`}>
            <p>
              <b>Our Fight for Liberty</b>
            </p>
            <p>
              We at Students For Liberty are not armed with swords of steel but
              with something far stronger.
              <strong>
                Our swords are made of the sound ideas of liberty. Our armor is
                civil discourse.  Our helmets forged from humility. Our boots
                are well traveled in the path laid down by the great minds of
                Aristotle, Jefferson, Hayek, and Friedman.
              </strong>
            </p>
            <p>
              And with this arsenal, we arm squadrons of volunteer students
              worldwide to fill the intellectual trenches. These students—brave,
              hard-working, and principled—are prepared to defend liberty no
              matter who holds the reins of power.
            </p>
            <p>
              <strong>Eternal Vigilance</strong>
            </p>
            <p>
              As the old adage reminds us,{" "}
              <strong>"The price of liberty is eternal vigilance."</strong> Yes,
              we (and our students) have exposed and overcome terrible regimes
              of misinformation and control, but we cannot allow complacency to
              creep in. The pendulum will swing back. It always does. And when
              it does, it’s up to us to ensure it doesn’t swing too far—or to
              stop it altogether.
            </p>
            <p>
              <strong>How?</strong> By continuing to produce leaders. The
              individuals who will shape academia, guide governments, build
              businesses, influence policy, and transform society.
            </p>
            <p>
              <strong>Our Proven Track Record</strong>
            </p>
            <p>
              In just 15 years, Students For Liberty has become a global
              movement, a conspiracy for liberty to leave people alone to grow
              and prosper. Consider the numbers:
            </p>
            <ul>
              <li>
                <strong>10,000+ alumni</strong> across dozens of countries.
              </li>
              <li>
                <strong>200+ new think tanks</strong> founded globally.
              </li>
              <li>
                <strong>Dozens of principled politicians</strong> elected to
                serve their nations.
              </li>
              <li>
                <strong>Multi-million-dollar businesses</strong> started by SFL
                alumni.
              </li>
              <li>
                <strong>1.5 million+ event attendees</strong> attracted by
                events organized by our army of volunteers.
              </li>
            </ul>
            <p>
              This isn’t just theory—it’s reality. Our alumni already
              collaborate to drive meaningful change. Some as successful
              entrepreneurs who reinvest in the cause of liberty. Others elected
              to office and guided by the values they embraced as students.
              Still others lead think tanks that produce groundbreaking research
              to make the case for individual freedom and limited government.
            </p>
            <p>
              <strong>Scaling the Movement</strong>
            </p>
            <p>
              Now, we are poised to scale this success. Our vision is bold: a
              worldwide network of leaders—students and alumni alike—dedicated
              to creating a freer world. They’ve been in the trenches as SFL
              students, and they continue the fight as leaders for liberty.
              Together, we are building a future where individuals are free to
              create wealth, exchange peacefully, and improve society for all.
            </p>
            <p>
              <strong>Thank You</strong>
            </p>
            <p>
              None of this would be possible without you. Your support, your
              passion, your belief in this mission—it’s what drives us forward.
              Together, we’re turning the tide and securing the future of
              liberty for generations to come.
            </p>
            <p>
              Thank you for being part of this fight. Thank you for your
              vigilance.
            </p>
            <p>
              <strong>Semper pro libertate,</strong>
            </p>
            <p>
              <strong>Wolf </strong>
            </p>
          </div>

          <a href="#" onClick={handleReadMoreClick}>
            {isExpanded ? "Read less ‹" : "Read full letter ›"}
          </a>
        </div>
        <div className="right-wrapper">
          <div className="image-wrapper">
            <img src={image} alt="dr.Wolf Von Laer" />
            <img src={signature} alt="Signature" />
          </div>
          <p>
            <strong>Dr. Wolf Von Laer</strong>
          </p>
          <p>CEO, Students For Liberty</p>
        </div>
      </div>
    </section>
  );
};

export default LetterFromCEO;
