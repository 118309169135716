import React, { useState } from "react";
import { revenue as segments } from "../../resources/finances";
import "./Chart.css";

const InteractiveChart = () => {
  const [hoveredSegment, setHoveredSegment] = useState(1);

  const handleMouseEnter = (id) => {
    setHoveredSegment(id);
  };

  const handleMouseLeave = () => {
    setHoveredSegment(1);
  };

  const activeSegment = segments.find(
    (segment) => segment.id === hoveredSegment
  );

  return (
    <div className="chart-container-one chart-svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 166 150"
        className="chart-svg"
      >
        <g>
          <path
            d="M150.07,75.04c0,41.44-33.59,75.03-75.03,75.03S0,116.48,0,75.04,33.6,0,75.04,0c23.64,0,45.89,11.14,60.06,30.06l-60.06,44.98h75.03Z"
            fill="#eec499"
            className={hoveredSegment === 1 ? "segment-hover" : ""}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          />
          <path
            d="M144.58,26.25c5.45,7.28,9.53,15.48,12.04,24.22l-72.1,20.76,60.06-44.98Z"
            fill="#d8c1d9"
            className={hoveredSegment === 2 ? "segment-hover" : ""}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          />
          <path
            d="M159.89,51.35c1.24,4.3,2.09,8.7,2.55,13.14l-74.65,7.62,72.1-20.76Z"
            fill="#85c1c7"
            className={hoveredSegment === 3 ? "segment-hover" : ""}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          />
          <path
            d="M165.94,65.5c.25,2.53.38,5.07.38,7.62h-75.03l74.65-7.62Z"
            fill="#fac813"
            className={hoveredSegment === 4 ? "segment-hover" : ""}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          />
        </g>

        <text
          x="52"
          y="72"
          textAnchor="middle"
          fontSize="16"
          fill="#000"
          fontWeight="bold"
          className="svg-number"
        >
          {activeSegment.percentage}
        </text>
        <text
          className="svg-text"
          x="55"
          y="82"
          textAnchor="middle"
          fontSize="7"
          fontWeight="normal"
          fill="#000"
        >
          {activeSegment.label}
        </text>
      </svg>
    </div>
  );
};

export default InteractiveChart;
