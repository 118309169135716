import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import stories from "../resources/stories";

import "./single-storie.css";

const SingleStorie = () => {
  const params = useParams();
  const [region, location] = params.id.split("-");
  const storie = stories[region].find((storie) => storie.location === location);
  const { name, image, descr } = storie;
  return (
    <>
      <section className="single-storie bg-grey">
        <div className="container">
          <HashLink to="/#global-presence">‹ Get back</HashLink>
          <div className="storie-content-wrapper">
            <img src={image} alt={location} className="border-radius-20" />
            <div>
              <span>{location}</span>
              <h4>{name}</h4>

              {descr.map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleStorie;
